import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import dynamic from "next/dynamic";
import Image from "next/image";
import { logo as logoLight, logoDark } from "../../public/assets";
const Icon = dynamic(() => import("@material-ui/core/Icon"));
import liveIcon from "../../public/assets/images/icons/live.svg";
import homeIcon from "../../public/assets/images/icons/home.svg";
import blazeIcon from "../../public/assets/images/icons/flash.svg";

function MenuItems() {

  const router = useRouter();

  useEffect(() => {
    // const currentPath = router.pathname;
    // if (currentPath.includes("/blaze")) {
    //   setMenuItem("blaze");
    // } else if (currentPath.includes("/classes")) {
    //   setMenuItem("videos");
    // } else if (currentPath.includes("/doubt")) {
    //   setMenuItem("doubts");
    // } else if (currentPath === "/newsfeed") {
    //   setMenuItem("news");
    // } else if (currentPath === "/") {
    //   setMenuItem("homepage");
    // }
  }, [router.pathname]);

  return (
    <div className="smd:!flex !hidden header__middle-md">
      <svg
        width={121}
        height={77}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#a)">
          <path
            d="M12.256 65.512 10.5 62 61 10l49.5 49.5-1.507 4.218a2 2 0 0 1-3.207.827L61.325 25.277a4 4 0 0 0-5.483.177l-40.38 40.574a2 2 0 0 1-3.206-.516Z"
            fill="#232323"
            fillOpacity={0.07}
            style={{
              fill: "white",
            }}
          />
        </g>
        <path
          d="M9 59.397V5h103v54.397c0 1.781-2.154 2.674-3.414 1.414L67.571 19.796c-3.905-3.905-10.237-3.905-14.142 0L12.414 60.811C11.154 62.07 9 61.178 9 59.397Z"
          fill="#fff"
          style={{
            fill: "white",
          }}
        />
        <defs>
          <filter
            id="a"
            x={0.5}
            y={0}
            width={120}
            height={76.618}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={5}
              result="effect1_foregroundBlur_126_19"
            />
          </filter>
        </defs>
      </svg>
      <Link
        href="/blaze"
        onClick={(e) => {
          //   setMenuItem("blaze");
          //   handleClick(e, "/blaze");
          //   navigator && navigator.vibrate && navigator.vibrate(5);
          // setBounce(true);
          // setTimeout(() => setBounce(false), 150);
        }}
        className={
          "diamond"
          // isDark
          //   ? "navbar-item navbar-item-dark diamond"
          //   : "navbar-item diamond"
        }
        style={{
          textDecoration: "inherit",
          // animation:
          // location.pathname.includes("/blaze") &&
          // bounce &&
          // "bounce 0.15s ease both",
        }}
        id="blazeIntro"
      >
        <div className="nav__box__wrapper diamond__blaze">
          <Icon className="nav__icon">
            <Image
              height={100}
              width={100}
              className="nav__icon__img"
              src={blazeIcon}
              alt="Blaze Icon"
            />
          </Icon>
        </div>
      </Link>
    </div>
  );
}

export default function NavbarSEO({}) {
  const router = useRouter();
  return (
    <div
      className={"headerContainer"}
      style={{ minHeight: "var(--header-height)", background: "transparent" }}
    >
      <div className={"header"}>
        <div className="header__left" style={{ background: "white" }}>
          <div
            className={
              "header__logo header__logo__left !justify-start !transform-none smd:justify-center"
            }
          >
            <Link href="/">
              <Image
                height={100}
                width={100}
                className="header__leftImage"
                src={logoLight}
                alt="PuStack"
              />
            </Link>
          </div>
          <Link
            href="/"
            // onClick={(e) => {
            //   setMenuItem("homepage");
            //   handleClick(e, "/null");
            // }}
            className={"navbar-item"}
            style={{ textDecoration: "inherit" }}
            id="homeIntro"
          >
            <div className="nav__box__wrapper">
              <Icon className="nav__icon">
                <Image
                  height={100}
                  width={100}
                  className="nav__icon__img"
                  src={homeIcon}
                  alt="Home Icon"
                />
              </Icon>
            </div>
          </Link>
        </div>

        <MenuItems />

        <div className="header__right !flex-1" style={{ background: "white" }}>
          <Link
            href="/classes"
            style={{ textDecoration: "inherit" }}
            className={"navbar-item"}
            onClick={(e) => {
              //   setMenuItem("videos");
              //   handleClick(e, "/classes");
            }}
            id="classesIntro"
          >
            <div className="nav__box__wrapper">
              <Icon className="nav__icon">
                {/* {hasSessions && (
                  <div className="has__sessions">
                    <div className="red__wrapper">
                      <div className="dot__red" />
                      <div className="red__bg">
                        <Lottie
                          options={{
                            animationData: glowIndicator,
                            loop: true,
                          }}
                          speed={1.5}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
                <Image
                  height={100}
                  width={100}
                  className="nav__icon__img"
                  src={liveIcon}
                  alt="Live Icon"
                />
              </Icon>
            </div>
          </Link>
          <div className="header__right-group">
            <span className="nav__links">
              {/*<a href="https://tutor.pustack.com">Tutor Login</a>*/}
              <span
                className="explore_classroom"
                style={{
                  boxShadow: "0 0 32px #0079F2",
                  background: "#0079F2",
                }}
                onClick={() => {
                  // setIsSliderOpen(true);
                  // navigator && navigator.vibrate && navigator.vibrate(5);
                  //   setShowLandingPage(false);
                  // history.push('/auth?step=login');
                  window.localStorage.setItem("skipLandingPage", "true");
                  router.push("/");
                }}
              >
                Access Classroom
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
