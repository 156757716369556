import React, { useEffect, useState } from "react";
import { useFetchHierarchy } from "./api/cms";
import { fetchRelatedArticlesFromHierarchy, LevelItem } from "./cms";
import Link from "next/link";
import { BiCaretRight } from "react-icons/bi";
import { FaCaretRight } from "react-icons/fa";
import { PiCaretRightBold } from "react-icons/pi";
import Image from "next/image";
import pustackMiniLogo from "../../public/assets/images/book-logo-black.webp";
import ampersandImage from "../../public/assets/images/ampersand.png";

export default function MoreFromPustack({ selectedGrade, selectionPath }) {
  const { data: _hierarchy } = useFetchHierarchy({
    grade_id: selectedGrade?.id,
  });
  const [relatedLinks, setRelatedLinks] = useState<any[]>([]);

  useEffect(() => {
    if (!_hierarchy || !selectionPath || !selectedGrade) return;
    const _relatedLinks = fetchRelatedArticlesFromHierarchy({
      currentArticleSlug: selectionPath.at(-1),
      hierarchy: _hierarchy,
      selectionPath: [...selectionPath],
      selectedGrade,
    });

    setRelatedLinks(_relatedLinks);
  }, [_hierarchy, selectionPath, selectedGrade]);

  if (!relatedLinks.length) return null;

  return (
    <section className="pb-20">
      <div>
        <Image
          alt="Minerva"
          src={pustackMiniLogo}
          className="w-[25px]"
          style={{ transform: "scale(5)" }}
        />
        <hr className="border-dashed border-[#1f1d1a4d] mt-[10px]" />
        <hr className="border-dashed border-[#1f1d1a4d] mt-[1px]" />
      </div>
      <hr
        style={{
          borderColor: "#1f1d1a",
          borderBottom: 0,
        }}
        className="mt-[35px] md:mt-[60px]"
      />
      <hr
        style={{
          margin: "2px 0 10px",
          borderColor: "#1f1d1a",
          borderBottom: 0,
        }}
      />
      <div className="styles_title flex items-center gap-3 !mb-4">
        <Image
          src={ampersandImage}
          width={20}
          height={16}
          className="w-[20px] h-[16px]"
          alt="Ampersand"
        />
        <h2
          style={{ marginBottom: 0, marginTop: "4px" }}
          className="uppercase text-lg"
        >
          More from PuStack
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4 mt-8">
        {relatedLinks.map((relatedLink) => (
          <Link
            prefetch={true}
            href={relatedLink?.url}
            className="py-3 px-5 bg-[#801615] hover:bg-opacity-20 bg-opacity-10 rounded-lg flex items-center justify-between"
            key={relatedLink?.id}
          >
            <h5>{relatedLink?.name}</h5>
            <div className="bg-[#801615] w-5 h-5 flex items-center justify-center rounded-full text-sm text-white">
              <PiCaretRightBold />
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
}
