import React, { useContext, useEffect } from "react";
// import { ThemeContext, UserContext } from "../../context";
import { useRouter } from "next/router";
import { useGetArticle } from "../../components/SlateEditor/api/cms";
import BreadCrumb from "../../components/SlateEditor/BreadCrumb";
import SlateEditor from "../../components/SlateEditor/SlateEditor";
import { firestore } from "../../firebase-admin";
import { articleConverter, LevelItem } from "../../components/SlateEditor/cms";
import { Button } from "@nextui-org/button";
import NavbarSEO from "../../containers/global/navbar-seo";
import MoreFromPustack from "../../components/SlateEditor/MoreFromPustack";
import Head from "next/head";
import { Descendant } from "slate";
import underConstruction from "../../public/assets/images/under_construction.svg";
import Image from "next/image";

export const extractFirstWordsFromPostNodes = (
  data: Descendant[] | undefined,
  number = 200
) => {
  let wordCount = 0;
  let extractedText = "";

  if (!data) {
    return extractedText;
  }

  // Iterate over each item in the data array
  for (const item of data) {
    // @ts-ignore
    if (!item.type) continue;

    // @ts-ignore
    if (item.type === "paragraph") {
      // Extract text from the children of the paragraph
      for (const child of item.children) {
        // Skip empty text nodes
        // @ts-ignore
        if (!child.text) continue;
        // @ts-ignore
        const words = child.text.split(" ");
        wordCount += words.length;

        // Add words to the extractedText
        if (wordCount <= number) {
          // @ts-ignore
          extractedText += child.text + " ";
        } else {
          // If the current text block exceeds 200 words, add only the required words
          const remainingWords = number - (wordCount - words.length);
          extractedText += words.slice(0, remainingWords).join(" ");
          return extractedText.trim(); // Return the result once 200 words are reached
        }
      }
    }
  }
  return extractedText.trim(); // Return in case there are less than 200 words in total
};

function transformToFirestorePathWithLevels(str: string): string {
  const parts = str.split("/");
  const transformedPath: string[] = ["articles"]; // Start with 'articles'

  parts.forEach((part, index) => {
    transformedPath.push(part);

    // Insert level_X after each element, but not after the last one
    if (index < parts.length - 1) {
      transformedPath.push(`level_${index + 1}`);
    }
  });

  return transformedPath.join("/");
}

export const getServerSideProps = async (context) => {
  const { id } = context.params;

  const docPath = transformToFirestorePathWithLevels(id.join("/"));

  const snapshot = await firestore.doc(docPath).get();

  if (!snapshot.exists) {
    return {
      props: {
        notFound: true,
      },
    };
  }

  // const selectedLevels = formSelectedLevelsFromHierarchy(_hierarchy, id);

  return {
    props: { id, data: snapshot.data() },
  };
};

const formatName = (str: string) => {
  // slug to name
  return str
    .split("-")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");
};

export default function BlogPage({
  id,
  data,
  notFound,
}: {
  id?: string[];
  data?: any;
  notFound?: boolean;
}) {
  // const setOnlyLightMode = useContext(ThemeContext).setOnlyLightMode;
  // const [user] = useContext(UserContext).user;
  // const { id } = router.query;
  // const { data, error } = useGetArticle(Array.from([id])?.flat()?.join("/"));

  // useEffect(() => {
  //   setOnlyLightMode(true);
  // }, [setOnlyLightMode]);

  // useEffect(() => {
  //   if (user.sign_up_ts && !user.is_blog_author) {
  //     router.push("/");
  //   }
  // }, [user, router]);

  const description = extractFirstWordsFromPostNodes(data?.nodes, 200);
  const title = formatName(data?.selectionPath?.at(-1) ?? "Pustack");

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:url"
          content={`https://pustack-internal.vercel.app/${
            data?.selectedGrade?.id
          }/${id?.join("/")}`}
        />
        <meta property="og:site_name" content="Pustack" />
        <meta
          property="og:image"
          content={
            "https://pustack-internal.vercel.app/assets/images/logoDarkBg.webp"
          }
        />
        {/* <meta property="og:image" content={processedImageUrl3} />
        <meta property="og:image" content={processedImageUrl} /> */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={
            "https://pustack-internal.vercel.app/assets/images/logoDarkBg.webp"
          }
        />
      </Head>

      <NavbarSEO />
      <div className="max-w-[900px] px-3 w-full mx-auto">
        <div className="my-4 mt-10">
          <BreadCrumb
            selectionPath={data?.selectionPath}
            selectedGrade={data?.selectedGrade}
            readOnly
            dontAllowCreateItem
          />
        </div>
        <div className="flex items-center justify-end w-full">
          {/* {user?.is_blog_author && (
          <Link href={"/blog?path=" + id?.join("/")}>
            <Button className="py-1 px-3 !h-auto text-sm border bg-gray-200 rounded">
              Edit
            </Button>
          </Link>
        )} */}
        </div>
        {notFound && (
          <div className="my-5">
            <Image
              alt="Not Found"
              src={underConstruction}
              className="w-full max-w-[500px] opacity-40 text-center block mx-auto"
            />
            <p className="text-gray-400 text-center font-light mt-4">
              Work In Progress
            </p>
          </div>
        )}
        {!notFound && (
          <div className="rounded leading-[1.5]">
            {data?.nodes && (
              <SlateEditor
                key={id?.toString() ?? "no-key"}
                value={data?.nodes ?? []}
                showToolbar={false}
                readonly
              />
            )}
          </div>
        )}
        <div>
          <MoreFromPustack
            selectedGrade={data?.selectedGrade}
            selectionPath={data?.selectionPath}
          />
        </div>
      </div>
    </div>
  );
}

BlogPage.getLayout = "BlogPage";
